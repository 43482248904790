const GT_WEB_CLOUDFRONT = 'https://d3hz2dasjf76zw.cloudfront.net';

// const APP_ID = 'gt-unified-rep-only-live'; // app.gt - mpp
const APP_ID = 'gt-unified-app-test'; // app-test
// const APP_ID = 'gt-unified-dev'; // localhost
const IS_GT = true;

// const APP_ID = 'gt-unified-wl-test'; // test-nk
// const APP_ID = 'gt-unified-wl-dev'; // localhost
// const APP_ID = 'gt-unified-prosperhotels'; // prosperhotels
// const IS_GT = false; // whitelabel

const GT_VERSION = 'v0.0.1';
let APP_NAME_CONTACT = 'GuestTouch';
let APP_LOGO = GT_WEB_CLOUDFRONT + '/images/logo/gtlogo-color.svg';

// Prod check
// const GT_DOMAIN = 'https://gtengage.guesttouch.com';
const GT_DOMAIN = 'https://app.guesttouch.com';

// Default is Dev
let v_DATA_BACKEND_URL = 'https://app-test.guesttouch.com/gtapi/yyrb8wt31e-dev/v1';
if (APP_ID === 'gt-unified-rep-only-live') {
  v_DATA_BACKEND_URL = 'https://app-test.guesttouch.com/gtapi/yyrb8wt31e-dev/v1';
}
if (APP_ID === 'gt-unified-app-test') {
  v_DATA_BACKEND_URL = 'https://app-test.guesttouch.com/gtapi/yyrb8wt31e-dev/v1';
}
if (APP_ID === 'gt-unified-dev') {
  v_DATA_BACKEND_URL = 'https://app-test.guesttouch.com/gtapi/yyrb8wt31e-dev/v1';
}
if (APP_ID === 'gt-unified-wl-dev') {
  v_DATA_BACKEND_URL = 'https://app-test.guesttouch.com/gtapi/yyrb8wt31e-dev/v1';
  APP_LOGO = GT_WEB_CLOUDFRONT + '/images/logo/prosperhotels-logo-color.png';
  APP_NAME_CONTACT = 'ProsperHotels';
}
if (APP_ID === 'gt-unified-prosperhotels') {
  v_DATA_BACKEND_URL = 'https://app.guesttouch.com/gtapi/rhnawxfoub-prod/v1';
  APP_LOGO = GT_WEB_CLOUDFRONT + '/images/logo/prosperhotels-logo-color.png';
  APP_NAME_CONTACT = 'ProsperHotels';
}
// v_DATA_BACKEND_URL = `${v_DATA_BACKEND_URL}/engageapi/test`;
let v_DATA_BACKEND_URL_API_KEY = 'eMTpBmL1DO8jOBmZNnpwu7mHBhiyRRAd70rHVwkR';

// if (process.env.NODE_ENV === 'production') {
//   // Prod
//   v_DATA_BACKEND_URL = 'https://bu27gxkoz6.execute-api.us-west-2.amazonaws.com/rq3v82j0k1m5t6hpsns17qqg92spz6adidxkcvp4';
//   v_DATA_BACKEND_URL_API_KEY = 'KxxD5emGOz8sV6vY3Q49G5ETlhI3ddMKa7hkMkNo';
//
//   v_DATA_NF_BACKEND_URL = 'https://2g7fn6zrlc.execute-api.us-west-2.amazonaws.com/fiqbntkumqu8ee1u35v06xqk2ai631na1br03mb8';
//   v_DATA_NF_BACKEND_URL = `${v_DATA_NF_BACKEND_URL}/engageapi/nf`;
//   v_DATA_NF_BACKEND_URL = `${GT_DOMAIN}/engageapi/nf`;
//   v_DATA_NF_BACKEND_URL_API_KEY = 'EkvA5Hwt8c8mvqTrVNlZj30ndvvyq1s9735V6PHL';
//
//   v_DATA_WF_BACKEND_URL = 'https://2g7fn6zrlc.execute-api.us-west-2.amazonaws.com/fiqbntkumqu8ee1u35v06xqk2ai631na1br03mb8';
//   v_DATA_WF_BACKEND_URL = `${v_DATA_WF_BACKEND_URL}/engageapi/wf`;
//   v_DATA_WF_BACKEND_URL = `${GT_DOMAIN}/engageapi/wf`;
//   v_DATA_WF_BACKEND_URL_API_KEY = 'EkvA5Hwt8c8mvqTrVNlZj30ndvvyq1s9735V6PHL';
//
// }

module.exports = {
  APP_ID,
  // APP_ID: 'gt-unified-rep-only-live', // app.gt - mpp
  // APP_ID: 'gt-unified-dev', // localhost
  // APP_ID: 'gt-unified-app-test', // app-test
  // APP_ID: 'gt-unified-wl-test', // test-nk
  // APP_ID: 'gt-unified-wl-dev', // localhost
  GT_VERSION,
  APP_NAME: 'GT_UNIFIED',
  APP_NAME_CONTACT,
  DISPLAY_NAME: 'GT Unified',
  APP_LOGO,
  IS_GT,

  // Dev
  DATA_BACKEND_URL: v_DATA_BACKEND_URL,
  DATA_BACKEND_URL_API_KEY: v_DATA_BACKEND_URL_API_KEY,

  EMAIL_ALERT_URL: 'https://yhkxzzlvo5.execute-api.us-west-2.amazonaws.com/uowhrrhixqiieqez0btl791byp2t7iivzc7g7054',
  EMAIL_ALERT_API_KEY: 'l36mToJaS12Amdjm6D3PD3vFbg61CgCZ6QvKp1hY',
  EMAIL_ALERT_URL_GT_BASIC_KEY: 'PJjnDwbTBvXGRZ3CS2ZGqXrGA0iR8uLX',

  IMG_UPLOAD_URL: 'https://xooz4w4zi5.guesttouch.com/dapi/pubg8dh9i6ncy4c73awy',
  IMG_UPLOAD_URL_WITH_RESIZE: 'https://xooz4w4zi5.guesttouch.com/dapi/zavrcdujrebvr1cgljbp',

  PUSHER_APP_KEY: 'b73cfef6d5b814cd2214',
  PUSHER_APP_CLUSTER: 'us3',

  GOOGLE_TRANSLATE_API_KEY: 'AIzaSyDEw0qdpMdoMcBGjuyHv84BWxNpXphdjRE',

  TRIPADVISOR_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/tripadvisor-logo.png',
  BOOKING_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/booking-logo.png',
  EXPEDIA_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/expedia-logo.png',
  GOOGLE_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/google-logo.png',
  HOTELSCOM_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/hotelscom-logo.png',
  HOSTELWORLD_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/hostelworld-logo.png',
  MMT_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/mmt-logo.png',
  AGODA_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/agoda-logo.png',
  AIRBNB_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/airbnb-logo.png',
  VRBO_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/vrbo-logo.jpg',
  HOLIDAYCHECK_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/holiday-check-logo-small.png',
  HOLIDAYCHECK_LOGO_LONG: GT_WEB_CLOUDFRONT + '/images/ota/holiday-check-logo.png',
  YELP_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/yelp-logo.png',
  OPENTABLE_LOGO: GT_WEB_CLOUDFRONT + '/images/ota/opentable-logo.svg',

  CONNECT_REVIEWS_WIDGET_URL: 'https://d2b1q31aafwen6.cloudfront.net/dashboard/img/connect-reviews-widget.png',
  CONNECT_PMS_URL: 'https://d2b1q31aafwen6.cloudfront.net/dashboard/img/connect-pms.png',

  FEEDBACK_HAPPY: GT_WEB_CLOUDFRONT + '/images/gt/happiness-64x64.png',
  FEEDBACK_SAD: GT_WEB_CLOUDFRONT + '/images/gt/sad-64x64.png',

  REPUTATION_LOADER_URL: 'https://d2b1q31aafwen6.cloudfront.net/dashboard/img/loader.gif',

};
